<template>
  <div>
    <form
      @submit.prevent="!reference_id ? saveReference() : Update(reference_id)"
      @keydown="form.onKeydown($event)"
    >
      <h3 class="card-title" v-if="profile">
        Manage Reference :
      </h3>
      <b-row>
        <b-col lg="12">
          <b-row>
            <b-col cols="12" xl="4">
              <div class="form-group">
                <label>Name</label>
                <Input
                  v-model="form.name"
                  placeholder="Your Referer Name"
                  style="width: 100%"
                  type="text"
                />
                <has-error :form="form" field="name"></has-error>
              </div>
            </b-col>
            <b-col cols="12" xl="4">
              <div class="form-group">
                <label>Email</label>
                <Input
                  v-model="form.email"
                  placeholder="Your Referer Email"
                  style="width: 100%"
                  type="email"
                />
                <has-error :form="form" field="email"></has-error>
              </div>
            </b-col>
            <b-col cols="12" xl="4">
              <div class="form-group">
                <label>Number</label>
                <Input
                  v-model="form.number"
                  placeholder="Your Referer Number"
                  style="width: 100%"
                  type="number"
                />
                <has-error :form="form" field="number"></has-error>
              </div>
            </b-col>
            <b-col cols="12" xl="4">
              <div class="form-group">
                <label>Designation</label>
                <Input
                  v-model="form.designation"
                  placeholder="Your Referer Designation"
                  style="width: 100%"
                  type="text"
                />
                <has-error :form="form" field="designation"></has-error>
              </div>
            </b-col>
            <b-col cols="12" xl="4">
              <div class="form-group">
                <label>Company</label>
                <Input
                  v-model="form.company"
                  placeholder="Your Referer Company"
                  style="width: 100%"
                  type="text"
                />
                <has-error :form="form" field="company"></has-error>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <div class="w-100 text-right">
        <b-row class="pt-1">
          <b-col class="d-flex justify-content-between" cols="4" lg="6">
            <router-link to="/profile" v-if="profile">
              <b-button variant="primary" >
                Back to profile
              </b-button>
            </router-link>
          </b-col>
          <b-col cols="8" lg="6">
            <b-button variant="danger" @click="clear()">
              Clear
            </b-button>
            <b-button variant="primary" class="ml-1" type="submit" :disabled="form.busy">
              {{ reference_id ? "Update" : "Add" }}
            </b-button>
          </b-col>
        </b-row>
      </div>
    </form>
    <hr class="mt-2" />

    <div
      class="card border"
      v-for="(reference, index) in references"
      v-if="references.length"
      :key="index"
    >
      <div class="card-body">
        <b-row>
          <b-col
            cols="12"
            lg="12"
            class="d-flex justify-lg-content-between flex-column flex-lg-row"
          >
            <div class="order-1 order-lg-0">
              <h5 class="card-title font-weight-bolder font-weight-bold">
                {{ reference.name }}
              </h5>
            </div>
            <div class="order-0 order-lg-1 ml-auto">
              <button
                class="btn btn-primary btn-sm"
                @click="edit(reference.id)"
              >
                <feather-icon icon="EditIcon" />
              </button>
              <button
                class="btn btn-danger btn-sm ml-1"
                @click="destroy(reference.id)"
              >
                <feather-icon icon="TrashIcon" />
              </button>
            </div>
          </b-col>

          <b-col cols="12 mb-1">
            <h6 class="card-subtitle text-secondary">
              {{ reference.designation }}
            </h6>
          </b-col>
          <b-col cols="12 mb-1">
            <h6 class="card-subtitle text-secondary">
              {{ reference.company }}
            </h6>
          </b-col>
          <b-col cols="12 mb-1" v-if="reference.email">
            <h6 class="card-subtitle text-secondary">
              <b>Email: </b> {{ reference.email }}
            </h6>
          </b-col>
          <b-col cols="12 mb-1" v-if="reference.number">
            <h6 class="card-subtitle text-secondary">
              <b>Number: </b>{{ reference.number }}
            </h6>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
} from "bootstrap-vue";
import { HasError } from "vform/src/components/bootstrap5";

export default {
  props: ["profile"],
  data() {
    return {
      form: new Form({
        name: null,
        email: null,
        number: null,
        designation: null,
        company: null,
      }),
      reference_id: null,
      references: [],
    };
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    HasError,
  },
  mounted() {
    this.showReference();
  },
  methods: {
    clear() {
      this.reference_id = null;
      this.form.name = null;
      this.form.email = null;
      this.form.number = null;
      this.form.designation = null;
      this.form.company = null;
    },

    saveReference() {
      this.form
        .post("/candidate/reference")
        .then((response) => {
          this.s(response.data.message);
          this.clear();
          this.showReference();
        })
        .catch((e) => {
          this.w(e.response.data.error);
        });
    },

    edit(id) {
      axios
        .get("/candidate/reference/" + id)
        .then((res) => {
          this.reference_id = res.data.references.id;
          this.form.name = res.data.references.name;
          this.form.email = res.data.references.email;
          this.form.number = res.data.references.number;
          this.form.designation = res.data.references.designation;
          this.form.company = res.data.references.company;
        })
        .catch((e) => {
          this.e(e.data.error);
        });
    },

    Update(id) {
      this.form
        .put("/candidate/reference/" + id)
        .then((res) => {
          this.s(res.data.message);
          this.clear();
          this.showReference();
        })
        .catch((e) => {
          this.e(e.data.error);
        });
    },

    destroy(id) {
      axios
        .delete("/candidate/reference/" + id)
        .then((response) => {
          this.s(response.data.message);
          this.showReference();
        })
        .catch((e) => {
          this.e(e.data.error);
        });
    },

    showReference() {
      axios
        .get("/candidate/reference")
        .then((res) => {
          this.references = res.data.references;
        })
        .catch((e) => {
          this.e(e.data.error);
        });
    },
  },
};
</script>
