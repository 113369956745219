<template>
  <b-card>
    <BasicInfo profile="true" v-if="$route.params.slug=='basic-info'"/>
    <Education profile="true" v-else-if="$route.params.slug=='education'"/>
     <AddUrl profile="true" v-else-if="$route.params.slug=='add-url'"/>
    <Cv profile="true" v-else-if="$route.params.slug=='cv'"/>
    <Experience profile="true" v-else-if="$route.params.slug=='experience'"/>
    <Preference profile="true" v-else-if="$route.params.slug=='preference'"/>
    <Skill profile="true" v-else-if="$route.params.slug=='skill'"/>
    <Reference profile="true" v-else-if="$route.params.slug=='reference'"/>
    <Curriculum profile="true" v-else-if="$route.params.slug=='curriculum-activities'"/>
    <TrainingCertification profile="true" v-else-if="$route.params.slug=='training-certification'"/>
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";
import BasicInfo from "@/views/components/onboard/BasicInfo.vue";
import Cv from "@/views/components/onboard/Cv.vue";
import Experience from "@/views/components/onboard/Experience.vue";
import Preference from "@/views/components/onboard/Preference.vue";
import Skill from "@/views/components/onboard/Skill.vue";
import Education from "@/views/components/onboard/Education.vue";
import Reference from "@/views/components/onboard/Reference.vue";
import Curriculum from "@/views/components/onboard/Curriculum.vue";
import TrainingCertification from "@/views/components/onboard/TrainingCertification.vue";

export default {
  components: {
    TrainingCertification,
    BCard,
    BasicInfo,
    Cv,
    Experience,
    Preference,
    Skill,
    Education,
    Reference,
    Curriculum,
  },
};
</script>
